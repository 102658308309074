import { render, staticRenderFns } from "./ReturnIntegrations.vue?vue&type=template&id=25757e83&scoped=true"
import script from "./ReturnIntegrations.vue?vue&type=script&lang=js"
export * from "./ReturnIntegrations.vue?vue&type=script&lang=js"
import style2 from "./ReturnIntegrations.vue?vue&type=style&index=2&id=25757e83&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25757e83",
  null
  
)

export default component.exports